import { FC } from 'react';
import Link from 'next/link';
import { parseISO, format } from 'date-fns';
import { getTruncatedTxt } from '@/helpers/helpers';
import { ILastReview } from '@/interfaces/lastReviews';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import styles from './ScrollReviewItem.module.scss';

interface IScrollReviewItemProps {
  review: ILastReview;
}

const ScrollReviewItem: FC<IScrollReviewItemProps> = ({ review }) => {
  const { created_at, book_title, message, book_id, external_id, rating } = review;
  const bookRating = Number(rating);
  const bookAlias = `/book/${book_id}`;

  return (
    <div className={styles.scrollReview}>
      <div className={styles.scrollReview__info}>
        <Link prefetch={false} className={styles.scrollReview__imgWrap} href={bookAlias}>
          <img loading="lazy" className={styles.scrollReview__img} src={`${DEV_ABSOLUTE_URL}/covers_120/${external_id}.jpg`} width="104" height="148" alt={book_title} />
        </Link>
        <div className={styles.scrollReview__desc}>
          <div className={styles.scrollReview__date}>
            <time dateTime={created_at}>{format(parseISO(created_at), 'dd.MM.yyyy')}</time>
          </div>
          <Link prefetch={false} className={styles.scrollReview__title} href={bookAlias}>
            {getTruncatedTxt(book_title, 46)}
          </Link>
          <div className={styles.scrollReview__rating}>
            <span className={styles.scrollReview__ratingNumber}>{bookRating > 0 ? bookRating : 0}</span>
          </div>
          <div className={styles.scrollReview__text}>
            {getTruncatedTxt(message, 100)}{' '}
            <Link prefetch={false} className={styles.scrollReview__link} href={bookAlias}>
              Далее
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollReviewItem;
